import {
  useState,
  useEffect,
  useMemo,
  JSXElementConstructor,
  Key,
  ReactElement,
  lazy,
  Suspense,
} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "uikit/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import routes from "routes/routes";
import eventRoutes from "routes/event.routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import newBrandLogo from "assets/images/logo.png";

//pages
const SubscriberDetailed = lazy(() => import("./pages/SubscriberDetailed"));
const CreateOrganization = lazy(() => import("pages/CreateOrganization"));

//Other components
import Spinner from "./components/Spinner";
import { useAppSelector } from "./hooks/redux";
import { OrganizationRole } from "./constants/organizationRoles";

import { checkRouteAccess } from "./utils/functions";
import { getUserDetails } from "./features/user/userSlice";

import { useAppDispatch } from "./hooks/redux";
import LogRocket from "logrocket";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { loading, details, controlledEvent } = useAppSelector((state) => state.user);
  const reducerDispatch = useAppDispatch();

  const appRoutes = useMemo(
    () => (controlledEvent ? eventRoutes(controlledEvent.id) : routes),
    [controlledEvent]
  );

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!details) {
      reducerDispatch(getUserDetails());
    } else {
      LogRocket.identify(details?.name + " " + details?.organizationProfile?.orgName || "Unknown");
    }
  }, [details]);

  const renderInhertanceRoutes = (route: any) => {
    return (
      <Route path={route.route} key={route.key}>
        {route.inheritances.map((inheritance: any) => {
          return (
            <Route
              index={inheritance.isIndex}
              path={inheritance.route && inheritance.route}
              element={inheritance.component}
              key={inheritance.key}
            />
          );
        })}
      </Route>
    );
  };

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        sidenavOnly?: boolean;
        hidden?: boolean;
        type?: string;
        inheritances?: any[];
        roles?: OrganizationRole[];
      }) => {
        const isAllowed = checkRouteAccess(route.roles, details?.organizationProfile?.role);
        if (!isAllowed) {
          return;
        }
        if (route.type === "inherit") {
          return renderInhertanceRoutes(route);
        }

        if (route.sidenavOnly || route.hidden) {
          return;
        }
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  if (loading) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <Spinner />;
      </ThemeProvider>
    );
  }

  if (!details?.organizationProfile) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="*" element={<Navigate to="/create-organization" />} />
            <Route path="/create-organization" element={<CreateOrganization />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={newBrandLogo}
            brandName=""
            routes={appRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            userRole={details?.organizationProfile?.role}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Suspense fallback={<Spinner />}>
        <Routes>
          {getRoutes(appRoutes)}
          <Route
            key="subscriber-detailed"
            path="/subscribers/:id"
            element={<SubscriberDetailed />}
          />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
