import { Navigate } from "react-router-dom";
import { OrganizationRole } from "../constants/organizationRoles";

// @mui icons
import Icon from "@mui/material/Icon";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// Images
import { lazy } from "react";

const SubscribersList = lazy(() => import("../pages/SubscribersList"));
const OrdersList = lazy(() => import("../pages/OrdersList"));
const Analytics = lazy(() => import("../pages/Analytics"));
const TicketScanner = lazy(() => import("pages/TicketScanner"));
const AllEvents = lazy(() => import("pages/AllEvents"));
const CreateEvent = lazy(() => import("pages/CreateEvent"));
const EditEvent = lazy(() => import("pages/EditEvent"));
const OrderDetails = lazy(() => import("pages/OrderDetails"));
const DiscountCodeDetails = lazy(() => import("pages/DiscountCodeDetailed"));
const TeamManagement = lazy(() => import("pages/TeamManagement/TeamManagement"));
const Settings = lazy(() => import("pages/Settings"));

const routes = [

  {
    type: "collapse",
    name: "Organizer",
    key: "user-account",
    route: "/organizer/profile",
    icon: <Icon fontSize="medium">person</Icon>,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  {
    type: "redirect",
    name: "Organizer Events",
    route: "*",
    component: <Navigate key="redirect" to="/organizer/events" />,
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  {
    type: "divider",
    key: "divider-before-events",
    dividerStyles: {
      backgroundColor: "white",
      borderRadius: "4px",
      height: "5px",
      margin: "16px 0"
    },
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  {
    type: "collapse",
    name: "My Events",
    key: "events",
    route: "/organizer/events",
    icon: <Icon fontSize="medium">date_range</Icon>,
    component: <AllEvents />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  {
    type: "collapse",
    name: "Community Hub",
    key: "analytics",
    route: "/organizer/analytics",
    icon: <Icon fontSize="medium">insights</Icon>,
    disabled: true,
    component: <Analytics />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  {
    type: "collapse",
    name: "Ticket Scanner",
    key: "tickets-scanner",
    route: "/scanner",
    icon: <Icon fontSize="medium">qr_code_scanner</Icon>,
    component: <TicketScanner />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  {
    type: "collapse",
    name: "Create Event",
    key: "create-event",
    route: "/organizer/create",
    icon: <Icon fontSize="medium">add</Icon>,
    component: <CreateEvent />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  {
    type: "divider",
    key: "divider-before-org-settings",
    dividerStyles: {
      backgroundColor: "white",
      borderRadius: "4px",
      height: "5px",
      margin: "16px 0"
    },
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  {
    type: "collapse",
    name: "Organization Settings",
    key: "settings",
    noCollapse: true,
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/settings",
    roles: [OrganizationRole.ADMIN],
    component: <Settings />
  },
  {
    type: "collapse",
    name: "Manage Team",
    key: "team-management",
    icon: <PersonAddIcon fontSize="medium" />,
    route: "/team-management",
    component: <TeamManagement />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  }
];

export default routes;