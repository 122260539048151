import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ILoginPayload, IRegisterPayload } from "types/auth";
import { login, register } from "../../api/authRquests";
import { setMessage } from "./messageSlice";
import { AppThunk } from "../../store";
import { getUserDetails } from "./userSlice";
import { StorageItems } from "../../constants/storageItems";
import Cookies from "js-cookie";
import { COOKIE_DOMAIN } from "../../config/env-constants";
import { resetMaterialUIController } from "context";

interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
  error: string;
}

interface ILoginResponse {
  jwt: string;
}

export const loginUser = createAsyncThunk<
  ILoginResponse,
  ILoginPayload,
  { rejectValue: AxiosError }
>("auth/login", async (credentials: ILoginPayload, { rejectWithValue, dispatch }) => {
  try {
    const response = await login(credentials);
    const { status, data } = response;
    const token = data.jwt;
    if (status === 200) {
      localStorage.setItem(StorageItems.TOKEN, token);
    }
    await dispatch(getUserDetails());
    dispatch(
      setMessage({
        type: "success",
        message: "You have successfully authenticated !",
      })
    );
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: "There was an error while logging in ! Please try again !",
      })
    );
    return rejectWithValue(error);
  }
});

export const registerUser = createAsyncThunk<
  IRegisterPayload,
  IRegisterPayload,
  { rejectValue: AxiosError }
>("auth/register", async (registrationData: IRegisterPayload, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await register(registrationData);
    dispatch(
      setMessage({
        type: "success",
        message: "You have successfully registered !",
      })
    );
    return data;
  } catch (err) {
    const error = err as AxiosError;
    dispatch(
      setMessage({
        type: "error",
        message: error.message,
      })
    );
    return rejectWithValue(error);
  }
});

export const logoutUser = () => async (dispatch: any) => {
  try {
    // First clear all storage
    Cookies.remove(StorageItems.TOKEN, { 
      domain: COOKIE_DOMAIN,
      sameSite: 'none',
      secure: true
    });
    sessionStorage.clear();
    localStorage.clear();

    // Then update Redux state
    dispatch(clearState());

    // Finally redirect with full page reload
    const baseUrl = process.env.REACT_APP_BASENAME || '';
    const redirectUrl = `${window.location.origin}${baseUrl}/authentication/sign-in`;
    window.location.replace(redirectUrl);
    
  } catch (error) {
    console.error("Logout error:", error);
    dispatch(
      setMessage({
        type: "error",
        message: "Error during logout",
      })
    );
  }
};

const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  error: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },

    clearState: (state) => {
      state.isLoggedIn = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(loginUser.fulfilled, (state) => {
      state.loading = false;
      state.isLoggedIn = true;
    });
    builder.addCase(loginUser.rejected, (state, { payload }: PayloadAction<AxiosError>) => {
      state.loading = false;
      state.error = payload.message;
    });
  },
});

const { actions, reducer } = authSlice;

export const { setLoading, setErrors, clearState } = actions;

export const authSelector = (state: { authStore: AuthState }) => state.authStore;

export default reducer;
