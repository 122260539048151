import { ReactNode, useEffect, useState, MouseEvent } from "react";

// react-router-dom
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// @mui material
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";

// Material Dashboard 2 PRO React TS components
import MDBox from "uikit/MDBox";
import MDTypography from "uikit/MDTypography";
import MDAvatar from "uikit/MDAvatar";
import MDButton from "uikit/MDButton";

// Notifications
import NotificationItem from "examples/Items/NotificationItem";

// Sidenav essentials
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
  resetMaterialUIController,
} from "context";

// Redux & user logic
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { setControlledEvent } from "features/user/userSlice";
import { logoutUser } from "features/user/authSlice";

// Utility
import { checkRouteAccess } from "utils/functions";
import { OrganizationRole } from "constants/organizationRoles";

// react-query calls
import { useQuery } from "@tanstack/react-query";
import { getOrganizationSettings } from "api/organizationSettings";
import { getUserInfo } from "api/authRquests";

// Fallback image if org logo is missing
import defaultAvatar from "assets/images/default-profile.png";

// Declaring props types for Sidenav
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: string;
  brandName: string;
  userRole: OrganizationRole;
  routes: {
    [key: string]:
      | ReactNode
      | string
      | {
          [key: string]:
            | ReactNode
            | string
            | {
                [key: string]: ReactNode | string;
              }[];
        }[];
  }[];
  [key: string]: any;
}

function Sidenav({ color, brand, brandName, routes, userRole, ...rest }: Props): JSX.Element {
  // Sidenav states
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);

  // Menu state for logout button
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  // Material UI Controller
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  // Router
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  // Break down the route to highlight correct collapses
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  // Redux user details
  const { details } = useAppSelector((state) => state.user);

  // 1) Grab orgId
  const orgId = details?.organizationProfile?.orgId;

  // 2) useQuery for org settings
  const { data: orgSettingsResponse } = useQuery({
    queryKey: ["organizationSettings", orgId],
    queryFn: () => getOrganizationSettings(orgId),
    enabled: Boolean(orgId),
  });
  const orgSettings = orgSettingsResponse?.data;

  // 3) useQuery for user info
  const { data: userInfoResponse } = useQuery({
    queryKey: ["userDetails"],
    queryFn: getUserInfo,
  });
  const userInfo = userInfoResponse?.data;

  // Final email fallback
  const finalEmail =
    orgSettings?.organizationInfoView?.publicEmail ||
    userInfo?.email ||
    "";

  // Decide text color based on theme
  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  // Handlers
  const closeSidenav = () => {
    setMiniSidenav(dispatch, true);
    // For mobile, we also want to reset these states
    setTransparentSidenav(dispatch, false);
    setWhiteSidenav(dispatch, false);
  };

  const handleBackToMainClick = () => {
    dispatch(setControlledEvent(null));
  };

  // Manage collapses
  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  // Toggle miniSidenav on screen resize
  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Nested collapse rendering
  const renderNestedCollapse = (collapse: any) =>
    collapse.map(({ name, route, key, href }: any) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

  // Collapse rendering
  const renderCollapse = (collapses: any) =>
    collapses.map(({ name, collapse, route, href, key }: any) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }: any) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        // Either an external link or a normal NavLink
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }

      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Renders top-level routes
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route, disabled, hidden, roles }: any) => {
      let returnValue;

      // Check access
      const isAllowed = checkRouteAccess(roles, userRole);
      if (!isAllowed || hidden) return null;

      if (type === "collapse") {
        // external link
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        }
        // single route
        else if (noCollapse && route) {
          returnValue = (
            <NavLink to={!disabled && route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={route === pathname}
                isDisabled={disabled}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        }
        // collapsible group
        else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() =>
                openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }

        if (key === "user-account") {
          const orgLogo =
            orgSettings?.organizationInfoView?.logoUrl || defaultAvatar;
          const orgName =
            orgSettings?.name || "Organization Name";

          const MAX_ORG_NAME_LENGTH = 20;
          const truncatedOrgName =
            orgName.length > MAX_ORG_NAME_LENGTH
              ? orgName.slice(0, MAX_ORG_NAME_LENGTH) + "..."
              : orgName;
        
          const orgNameNode = (
            <MDTypography
              variant="h6"           
              fontWeight="medium"
              color={textColor}      
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 140,     
              }}
            >
              {truncatedOrgName}
            </MDTypography>
          );
        
          // Circle avatar
          const avatarNode = (
            <MDAvatar
              src={orgLogo}
              alt="Organization Logo"
              variant="circular"
              size="sm"
            />
          );
        
          returnValue = (
            <SidenavCollapse
              key={key}
              name={orgNameNode}
              icon={avatarNode}
              active={key === collapseName}
              open={openCollapse === key}
              noCollapse={noCollapse}
              onClick={() =>
                openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }

        // multi-event scenario
        if (key === "back-to-main") {
          returnValue = (
            <NavLink to={route} key={key} onClick={handleBackToMainClick}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        }
      } 
      else if (type === "title") {
        // simple text header
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      }
      else if (type === "divider") {
        // dividing line
        returnValue = (
          <MDBox
            key={key}
            sx={{
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider
              sx={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                height: "2px",
                margin: "16px 0",
              }}
            />
          </MDBox>
        );
      }

      return returnValue;
    }
  );

  // ---- LOGOUT logic for the bottom button's menu ----
  const handleLogout = () => {
    resetMaterialUIController(dispatch);
    dispatch(logoutUser());
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // The small menu with a single item: "Logout"
  const renderLogoutMenu = () => (
    <Menu
      anchorEl={menuAnchorEl}
      anchorReference={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menuAnchorEl)}
      onClose={handleCloseMenu}
      sx={{ mt: -1 }}
    >
      <NotificationItem
        onClick={handleLogout}
        icon={<Icon>logout</Icon>}
        title="Logout"
      />
    </Menu>
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100dvh',
        overflowY: 'auto',
        paddingBottom: 'env(safe-area-inset-bottom)',
        '@supports not (padding: env(safe-area-inset-bottom))': {
          paddingBottom: { xs: '4rem', sm: 0 },
        }
      }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        {/* Close icon for mobile */}
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>

        {/* Top brand/logo if brand prop is provided */}
        <MDBox
          component={NavLink}
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {brand && (
            <MDBox
              component="img"
              src={brand}
              alt="Brand"
              sx={{
                width: "90px",
                height: "auto",
              }}
            />
          )}
        </MDBox>
      </MDBox>

      {/* Divider below the logo */}
      <MDBox sx={{ px: 2 }}>
        <Divider sx={{ backgroundColor: "#fff", borderRadius: "4px", height: "2px", my: 2 }} />
      </MDBox>

      {/* Main content area - will flex grow to fill space */}
      <MDBox flex={1} overflow="auto">
        <List>{renderRoutes}</List>
      </MDBox>

      {/* Bottom button - no longer absolute positioned */}
      <MDButton
        variant="gradient"
        color="success"
        fullWidth
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 1.5,
          px: 2,
          background: "#3FE0C4",
          "&:hover": {
            background: "#3FE0C4",
          },
          mt: 'auto',
          marginBottom: 'env(safe-area-inset-bottom)',
          '@supports not (margin: env(safe-area-inset-bottom))': {
            mb: { xs: '1rem', sm: 0 },
          }
        }}
        onClick={handleOpenMenu}
      >
        <MDTypography
          variant="button"
          fontWeight="medium"
          color="white"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "160px", 
            mr: 5,
          }}
        >
          {finalEmail || "No Email"}
        </MDTypography>

        <Icon sx={{ ml: "auto" }}>logout</Icon>
      </MDButton>

      {renderLogoutMenu()}
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

export default Sidenav;
