import request from "../utils/request";
import { IOrganizationSettings, IOrganizationInfoUpdate, ISocialLinksUpdate, ICompanyDetailsUpdate, IOrganizationLogoUpdate } from "../types/organizationSettings";
import { AxiosResponse } from "axios";

export const getOrganizationSettings = (id: number) => {
  return request({
    url: `/organizations/${id}`,
    method: "get",
  }) as Promise<AxiosResponse<IOrganizationSettings>>;
};

export const updateOrganizationSettings = async (organization: Partial<IOrganizationSettings>) => {
  // First get current organization data
  const currentOrg = await getOrganizationSettings(organization.id!);
  
  // Merge current data with updates
  const payload = {
    ...currentOrg.data,
    ...organization,
    organizationInfoView: {
      ...currentOrg.data.organizationInfoView,
      ...(organization.organizationInfoView || {}),
    },
    companyDetailsView: {
      ...currentOrg.data.companyDetailsView,
      ...(organization.companyDetailsView || {}),
    },
  };

  // Try PUT instead of POST
  return request({
    url: `/organizations/${organization.id}`, // Include ID in URL
    method: "put",
    data: payload,
  }) as Promise<AxiosResponse<IOrganizationSettings>>;
};

export const uploadOrganizationImage = (orgId: number, formData: FormData) => {
  return request({
    url: `/organizations/${orgId}/organizationLogo`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateOrganizationInfo = (organizationId: number, info: IOrganizationInfoUpdate) => {
  return request({
    url: `/organizations/${organizationId}/organizationInfo`,
    method: "put",
    data: info,
  }) as Promise<AxiosResponse<IOrganizationSettings>>;
};

export const updateOrganizationSocialLinks = (organizationId: number, links: ISocialLinksUpdate) => {
  return request({
    url: `/organizations/${organizationId}/socialLinks`,
    method: "put",
    data: links,
  }) as Promise<AxiosResponse<IOrganizationSettings>>;
};

export const updateCompanyDetails = (organizationId: number, details: ICompanyDetailsUpdate) => {
  return request({
    url: `/organizations/${organizationId}/companyDetails`,
    method: "put",
    data: details,
  }) as Promise<AxiosResponse<IOrganizationSettings>>;
};

export const updateOrganizationLogo = (organizationId: number, logoUrl: string) => {
  return request({
    url: `/organizations/${organizationId}/organizationLogo`,
    method: "put",
    data: {
      logoUrl,
      backgroundLogoUrl: ""
    },
    headers: {
      'Content-Type': 'application/json'
    }
  }) as Promise<AxiosResponse<IOrganizationSettings>>;
}; 